<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <router-link :to="{
              name: 'monthly-plans',
            }">
              <button type="button" class="btn btn-sm btn-blue waves-effect waves-light mb-2">
                <i class="mdi mdi-arrow-left"></i>
                Back
              </button>
            </router-link>
          </div>
          <h4 class="page-title">Monthly Plan Create</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <place-holder v-if="loading"></place-holder>
    <div class="row" v-show="!loading">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="form-horizontal" v-if="!loading">
              <div class="d-flex">
                <div class="mb-3 d-flex">
                  <label class="me-3 col-form-label">Budget Year </label>
                  <div class="col-2 col-xl-2" style="width: 160px">
                    <v-select label="name" v-model="year" :options="years" :clearable="false" :reduce="(name) => name.id"
                      @option:selected="getPlansByYear($event)">
                    </v-select>
                  </div>
                </div>

                <div class="row mb-3 ms-3 d-flex">
                  <label class="col-2 col-xl-2 col-form-label w-75">Month
                  </label>
                  <div class="col-2 col-xl-2" style="width: 200px">
                    <v-select label="name" v-model="month" :options="months" :clearable="false"
                      :reduce="(name) => name.id" @option:selected="getPlansByMonth()">
                    </v-select>
                  </div>
                  <!-- <div class="col-4 col-xl-5">
                  <a href="javascript:void(0)" class="">
                    <button
                      v-if="this.month == ''"
                      style="cursor: not-allowed"
                      type="button"
                      class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                      data-bs-toggle="tooltip"
                      title="Please Choose Month First!"
                    >
                      <i class="mdi mdi-plus-circle"></i> Add
                    </button>
                    <button
                      v-else-if="
                        getStartAndlastDay.lastDay < current_year_month_day
                      "
                      style="display: none"
                      type="button"
                      class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                      data-bs-toggle="tooltip"
                      title="You can't add new plan after last day of selected month is passed over current date!"
                    >
                      <i class="mdi mdi-plus-circle"></i> Add
                    </button>
                    <button
                      v-else
                      @click="addMonthlyPlan()"
                      type="button"
                      class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                    >
                      <i class="mdi mdi-plus-circle"></i> Add
                    </button>
                  </a>
                </div> -->
                </div>
              </div>

              <div class="table-responsive">
                <table id="monthly-datatable" class="table" v-if="!loading">
                  <thead>
                    <tr>
                      <th style="min-width: 50px !important">No.</th>
                      <th style="min-width: 130px !important">Employee Type</th>
                      <th style="min-width: 280px !important">Module Group</th>
                      <th style="min-width: 280px !important">Module</th>
                      <th style="min-width: 150px !important">From Date</th>
                      <th>End Date</th>
                      <th>Start Time</th>
                      <th>Enrolled Expired Date</th>
                      <th>Training Hours</th>
                      <th>Training Method</th>
                      <th>Location/Link</th>
                      <th>Trainer</th>
                      <th style="min-width: 120px !important">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="!loading">
                    <tr v-for="(item, index) in monthlyPlanDatas" :key="index"
                      :class="{ customHeight: monthlyPlanDatas.length == 1 }">
                      <td>{{ index + 1 }}</td>

                      <td style="min-width: 140px !important">
                        <v-select id="vue-selectCss" label="name" :class="{
                          confirmDisable: confirmPlan(item),
                          plan_active: activePlan(item),
                        }" :options="employee_types" :reduce="(name) => name.id" v-model="item.employee_type"
                          @option:selected="getModuleGroupsByType($event, index)
                            " :clearable="false" :disabled="confirmPlan(item) ? true : false" :data-bs-toggle="confirmPlan(item) ? 'tooltip' : false
    " :title="confirmPlan(item)
    ? 'You cannot change after plan is over 3 days from created date!'
    : ''
    ">
                        </v-select>
                      </td>

                      <td style="min-width: 280px !important">
                        <v-select id="vue-selectCss" label="name" :class="{
                          confirmDisable: confirmPlan(item),
                          plan_active: activePlan(item),
                        }" v-model="item.module_group" :options="filter_module_groups[index]" @search:focus="changeModuleGroup(item.employee_type, index)
  " :reduce="(name) => name.id" @option:selected="getModulesByGroup($event, index)" :clearable="false"
                          :disabled="confirmPlan(item) ? true : false" :data-bs-toggle="confirmPlan(item) ? 'tooltip' : false
                            " :title="confirmPlan(item)
    ? 'You cannot change after plan is over 3 days from created date!'
    : ''
    ">
                        </v-select>
                      </td>

                      <td style="min-width: 280px !important">
                        <v-select id="vue-selectCss" label="name" :class="{
                          confirmDisable: confirmPlan(item),
                          plan_active: activePlan(item),
                        }" v-model="item.module" :options="filter_modules[index]" :reduce="(name) => name.id"
                          @option:selected="getModuleDuration(item.module, index)
                            " @search:focus="changeModule(item.module_group, index)" :clearable="false"
                          :disabled="confirmPlan(item) ? true : false" :data-bs-toggle="confirmPlan(item) ? 'tooltip' : false
                            " :title="confirmPlan(item)
    ? 'You cannot change after plan is over 3 days from created date!'
    : ''
    ">
                        </v-select>
                      </td>

                      <td style="min-width: 150px !important">
                        <flat-pickr v-model="item.from_date" class="form-control" :class="{
                          confirmDisable: confirmPlan(item),
                          plan_active: activePlan(item),
                        }" placeholder="Select From Date" name="date" :config="flatPickrDateConfig"
                          @on-change="changeFromDate(item)" :disabled="confirmPlan(item) ? true : false" :data-bs-toggle="confirmPlan(item) ? 'tooltip' : false
                            " :title="confirmPlan(item)
    ? 'You cannot change after plan is over 3 days from created date!'
    : ''
    "></flat-pickr>
                      </td>

                      <td style="min-width: 150px !important">
                        <flat-pickr v-model="item.to_date" class="form-control" :class="{
                          confirmDisable: confirmPlan(item),
                          plan_active: activePlan(item),
                        }" placeholder="Select To Date" name="date" :config="flatPickrDateConfigForToDate"
                          @on-change="changeToDate(item)" :disabled="confirmPlan(item) ? true : false" :data-bs-toggle="confirmPlan(item) ? 'tooltip' : false
                            " :title="confirmPlan(item)
    ? 'You cannot change after plan is over 3 days from created date!'
    : ''
    "></flat-pickr>
                      </td>

                      <td style="min-width: 150px !important">
                        <flat-pickr v-model="item.start_time" class="form-control" :class="{
                          confirmDisable: confirmPlan(item),
                          plan_active: activePlan(item),
                        }" placeholder="Select start date" name="date" :config="flatPickrTimeConfig"
                          :disabled="confirmPlan(item) ? true : false" :data-bs-toggle="confirmPlan(item) ? 'tooltip' : false
                            " :title="confirmPlan(item)
    ? 'You cannot change after plan is over 3 days from created date!'
    : ''
    "></flat-pickr>
                      </td>

                      <td style="min-width: 150px !important">
                        <flat-pickr v-model="item.expired_date" class="form-control" :class="{
                          confirmDisable: confirmPlan(item),
                          plan_active: activePlan(item),
                        }" placeholder="Select enrolled expired date" name="date"
                          :config="flatPickrDateConfigForExpired" @on-change="changeExpiredDate(item)"
                          :disabled="confirmPlan(item) ? true : false" :data-bs-toggle="confirmPlan(item) ? 'tooltip' : false
                            " :title="confirmPlan(item)
    ? 'You cannot change after plan is over 3 days from created date!'
    : ''
    "></flat-pickr>
                      </td>

                      <td style="min-width: 150px !important">
                        <input type="text" v-model="module_duration[index]" id="duration" name="duration"
                          class="form-control custom-form-control" :class="{
                            confirmDisable: confirmPlan(item),
                            plan_active: activePlan(item),
                          }" placeholder="Training Hour" disabled data-bs-toggle="tooltip"
                          title="You can not change module duration!" />
                      </td>

                      <td style="min-width: 150px !important">
                        <v-select id="vue-selectCss" label="name" v-model="item.training_type" :class="{
                          confirmDisable: confirmPlan(item),
                          plan_active: activePlan(item),
                        }" :options="trainning_methods" :clearable="false" :disabled="confirmPlan(item) ? true : false"
                          :data-bs-toggle="confirmPlan(item) ? 'tooltip' : false
                            " :title="confirmPlan(item)
    ? 'You cannot change after plan is over 3 days from created date!'
    : ''
    ">
                        </v-select>
                      </td>

                      <td style="min-width: 150px !important">
                        <input v-model="item.location" type="text" id="location" name="location" class="form-control"
                          :class="{
                            confirmDisable: confirmPlan(item),
                            plan_active: activePlan(item),
                          }" :placeholder="item.training_type == 'Online' ? 'Link' : 'Location'
  " :disabled="confirmPlan(item) ? true : false" :data-bs-toggle="confirmPlan(item) ? 'tooltip' : false
    " :title="confirmPlan(item)
    ? 'You cannot change after plan is over 3 days from created date!'
    : ''
    " />
                      </td>

                      <td style="min-width: 210px !important">
                        <v-select id="vue-selectCss" multiple label="name" :class="{
                          confirmDisable: confirmPlan(item),
                          plan_active: activePlan(item),
                        }" v-model="item.trainers" :options="trainers" :reduce="(name) => name.id" :clearable="false"
                          :disabled="confirmPlan(item) ? true : false" :data-bs-toggle="confirmPlan(item) ? 'tooltip' : false
                            " :title="confirmPlan(item)
    ? 'You cannot change after plan is over 3 days from created date!'
    : ''
    ">
                        </v-select>
                      </td>

                      <td>
                        <span>
                          <a v-if="item.id != 0" href="javascript:void(0)" class="action-icon disable-button"
                            data-bs-toggle="tooltip" title="You can't delete existing plan!"><i
                              class="fas fa-trash-alt mx-1"></i></a>

                          <a v-else @click="deleteRow(index)" href="javascript:void(0)" class="action-icon"><i
                              class="fas fa-trash-alt mx-1"></i></a></span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <div class="modify-button-css mt-3">
                      <a href="javascript:void(0)" class="">
                        <button v-if="this.month == ''" style="cursor: not-allowed" type="button"
                          class="btn btn-sm btn-blue waves-effect waves-light" data-bs-toggle="tooltip"
                          title="Please Choose Month First!">
                          <i class="mdi mdi-plus-circle"></i> Add
                        </button>
                        <button v-else-if="getStartAndlastDay.lastDay < current_year_month_day
                          " style="display: none" type="button" class="btn btn-sm btn-blue waves-effect waves-light"
                          data-bs-toggle="tooltip"
                          title="You can't add new plan after last day of selected month is passed over current date!">
                          <i class="mdi mdi-plus-circle"></i> Add
                        </button>
                        <button v-else @click="addMonthlyPlan()" type="button" class="
                                                                btn btn-sm btn-blue
                                                                waves-effect waves-light
                                                                mb-2
                                                              ">
                          <i class="mdi mdi-plus-circle"></i> Add
                        </button>
                      </a>
                    </div>
                  </tfoot>
                </table>
              </div>
              <div class="col-md-12" v-if="!loading">
                <div class="text-center mt-3 mb-3">
                  <button type="button" class="btn w-sm btn-secondary me-5" @click="toMonthlySchedulePlan">
                    Cancel
                  </button>
                  <button v-if="this.monthlyPlanDatas == '' ||
                    getStartAndlastDay.lastDay < current_year_month_day
                    " type="button" class="btn w-sm btn-success waves-effect waves-light px-3"
                    style="cursor: not-allowed" data-bs-toggle="tooltip" :title="getStartAndlastDay.lastDay < current_year_month_day
                      ? 'You cannot add new plan after plan is over!'
                      : 'Data is nothing to save!'
                      ">
                    Save
                  </button>
                  <button v-else :disabled="isLoading ? true : false" type="button"
                    class="btn w-sm btn-success waves-effect waves-light px-3" @click="createMonthlyPlans()">
                    <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    {{ isLoading == true ? 'Loading...' : 'Save' }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";

import axios from "axios";
import moment from "moment";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";
import $ from "jquery";

//Components

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  mixins: [validationErrors],
  components: {
    vSelect,
    flatPickr,
  },
  data() {
    return {
      current_year_month_day: "",
      is_valid_date: true,
      budget_year: "",
      year: "",
      yearId: "",
      years: [],
      month: "",
      budget_year_date_from: "",
      budget_year_date_to: "",
      module_duration: [],
      filter_module_duration: "",
      modules: [],
      trainers: [],
      employee_types: [],
      filter_module_groups: [],
      filter_modules: [],
      months: [],
      sortMonth: [],
      plan_name: "",
      trainning_methods: ["Online", "Inclass", "Outbound"],

      flatPickrDateConfig: {
        minDate: null,
        maxDate: null,
        defaultDate: null,
      },

      flatPickrDateConfigForToDate: {
        minDate: null,
        maxDate: null,
        defaultDate: null,
      },

      flatPickrDateConfigForExpired: {
        minDate: null,
        maxDate: null,
        defaultDate: null,
      },

      flatPickrTimeConfig: {
        enableTime: true,
        noCalendar: true,
        defaultTime: "08:00:00",
        dateFormat: "H:i:s",
        altInput: true,
        altFormat: "H:i",
        minTime: "08:00",
        maxTime: "17:30",
      },

      monthlyPlanDatas: [
        {
          budget_year: null,
          month: null,
          employee_type: null,
          module_group: null,
          module: null,
          from_date: null,
          to_date: null,
          start_time: "08:00:00",
          training_type: null,
          location: null,
          trainers: null,
          expired_date: null,
          budget_name: null,
        },
      ],
      oldMonthlyPlanDatas: [],
      employee_type_id_for_date: "",

      baseUrl: process.env.VUE_APP_BASE_URL,
      defineExpDateForNormal: process.env.VUE_APP_DEFINE_EXP_DATE_FOR_NORMAL,
      defineExpDateForProgram: process.env.VUE_APP_DEFINE_EXP_DATE_FOR_PROGRAM,
      defineCreateDateForDisable:
        process.env.VUE_APP_DEFINE_CREATE_DATE_FOR_DISABLE,
      loading: false,
      isLoading: false
    };
  },
  validations() {
    return {
      month: { required },
    };
  },
  methods: {
    // async getBugetYearById() {
    //   await axios
    //     .get(`${this.baseUrl}admin/v1/get-budgetyears/${this.$route.params.id}`)
    //     .then((response) => {
    //       let result = response.data.data;
    //       this.budget_year_date_from = result.date_from;
    //       this.budget_year_date_to = result.date_to;
    //     });
    // },

    async getAllBudgetYear() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/yearly-training-plans`)
        .then((response) => {
          this.years = response.data.data;
          this.loading = false;
        });
    },

    async getPlansByYear(value) {
      this.yearId = value.id;
      this.month = "";
      await this.getBugetYearById(this.yearId);
      await this.getMonths(this.yearId);
    },

    async getBugetYearById(year_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/get-budgetyears/${year_id}`)
        .then((response) => {
          let result = response.data.data;
          this.budget_year_date_from = result.date_from;
          this.budget_year_date_to = result.date_to;
          this.loading = false;
          this.$Progress.finish();
        });
    },

    async getMonths(year_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/get-months/${year_id}`)
        .then((response) => {
          let obj = response.data.data;
          this.months = Object.keys(obj).map((key) => {
            return { id: key, name: obj[key] };
          });
          this.loading = false;
          this.$Progress.finish();
        });
    },

    // async getAllMonths() {
    //   this.loading = true;
    //   axios.get(`${this.baseUrl}admin/v1/get-months`).then((response) => {
    //     let obj = response.data.data;
    //     this.months = Object.keys(obj).map((key) => {
    //       return { id: key, name: obj[key] };
    //     });
    //     this.loading = false;
    //   });
    // },

    async addMonthlyPlan() {
      if (this.month) {
        //for adding new row for monthly plan
        let budget_year_from = this.getBudgetYear.from; //2021-2022 => 2021-10-01 => 2021
        let budget_year_to = this.getBudgetYear.to; //2021-2022 => 2022-09-01 => 2022
        let budget_year_name = budget_year_from + "-" + budget_year_to;
        let data = {
          id: 0,
          employee_type: null,
          module_group: null,
          module: null,
          from_date: null,
          to_date: null,
          start_time: this.flatPickrTimeConfig.defaultTime,
          training_type: null,
          location: null,
          trainers: null,
          expired_date: null,
          // budget_year: this.$route.params.id,
          budget_year: this.yearId,
          month: this.month,
          budget_name: budget_year_name,
        };
        this.monthlyPlanDatas.push(data);
      } else {
        this.toast.error("Please Choose Month First!");
      }
    },
    changeFromDate(selectItem) {
      if (selectItem.from_date) {
        this.monthlyPlanDatas.from_date = selectItem.from_date;
        this.monthlyPlanDatas.to_date = selectItem.to_date;

        var calculateExpDate = new Date(this.monthlyPlanDatas.from_date);

        if (selectItem.employee_type == 2) {
          calculateExpDate.setDate(
            calculateExpDate.getDate() -
            this.getPreDefineExpDateForNormal.expiered_date
          );
        }

        if (selectItem.employee_type == 1) {
          calculateExpDate.setDate(
            calculateExpDate.getDate() +
            this.getPreDefineExpDateForProgram.expiered_date
          );
        }

        selectItem.expired_date = moment(calculateExpDate).format("YYYY-MM-D");
        if (this.monthlyPlanDatas.from_date > this.monthlyPlanDatas.to_date) {
          this.toast.error("End date Is Smaller Than From Date!");
        }
      }
    },

    changeToDate(selectItem) {
      this.monthlyPlanDatas.from_date = selectItem.from_date;
      this.monthlyPlanDatas.to_date = selectItem.to_date;
      this.monthlyPlanDatas.expired_date = selectItem.expired_date;

      if (this.monthlyPlanDatas.from_date > this.monthlyPlanDatas.to_date) {
        this.toast.error("End Date Is Smaller Than From Date!");
      }
    },

    changeExpiredDate(selectItem) {
      this.monthlyPlanDatas.from_date = selectItem.from_date;
      this.monthlyPlanDatas.to_date = selectItem.to_date;
      this.monthlyPlanDatas.expired_date = selectItem.expired_date;

      var min_expired_date = new Date(this.monthlyPlanDatas.expired_date);

      if (selectItem.employee_type == 2) {
        min_expired_date.setDate(
          min_expired_date.getDate() +
          this.getPreDefineExpDateForNormal.expiered_date
        );
      }
      if (selectItem.employee_type == 1) {
        min_expired_date.setDate(
          min_expired_date.getDate() -
          this.getPreDefineExpDateForProgram.expiered_date
        );
      }

      var min_expired_date_format =
        moment(min_expired_date).format("YYYY-MM-DD");

      if (
        this.monthlyPlanDatas.from_date != null &&
        this.monthlyPlanDatas.from_date != ""
      ) {
        if (selectItem.employee_type == 2) {
          if (this.monthlyPlanDatas.from_date < min_expired_date_format) {
            this.toast.error(
              "Enrolled Expired Date is minimum 3 days greater than From Date!"
            );
          }
        }
        if (selectItem.employee_type == 1) {
          if (this.monthlyPlanDatas.from_date > min_expired_date_format) {
            this.toast.error(
              "Enrolled Expired Date is minimum 7 days less than From Date!"
            );
          }
        }
      }
    },

    //clean monthly plan form when no choose month
    async getOldMonthlyPlanDatas() {
      this.monthlyPlanDatas = this.oldMonthlyPlanDatas;
    },
    deleteRow(index) {
      this.monthlyPlanDatas.splice(index, 1);
    },

    async getAllModules() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },

    async getPlansByMonth() {
      this.loading = true;
      $("#vue-selectCss").removeClass("vs--disabled");
      if (this.month) {
        let budget_year_from = this.getBudgetYear.from;
        let budget_year_to = this.getBudgetYear.to;
        let budget_year_name = budget_year_from + "-" + budget_year_to;

        this.flatPickrDateConfig.minDate = this.getStartAndlastDay.firstDay; //get first day from selected month for flatPickr from_date and to_date
        this.flatPickrDateConfig.maxDate = this.getStartAndlastDay.lastDay; //get last day from selected month flatPickr MinDate => for from_date and to_date

        await axios
          .post(`${this.baseUrl}admin/v1/get-monthly-plans`, {
            // budget_year_id: this.$route.params.id,
            budget_year_id: this.yearId,
            month: this.month,
          })
          .then((response) => {
            let datas = response.data.data;
            this.monthlyPlanDatas = [];
            if (datas.length > 0) {
              datas.forEach((data, index) => {
                let oldPlan = null;
                let trainerLists = [];
                this.employee_type_id_for_date = data.employee_type_id;
                data.trainers.forEach((trainer) => {
                  trainerLists.push(trainer.user.employee_id);
                });
                oldPlan = {
                  id: data.id,
                  employee_type: data.employee_type_id,
                  module_group: data.module_group,
                  module: data.module,
                  from_date: data.from_date,
                  to_date: data.to_date,
                  start_time: data.start_time,
                  expired_date: data.expired_date,
                  training_type: data.training_type,
                  location: data.location,
                  trainers: trainerLists,
                  // budget_year: this.$route.params.id,
                  budget_year: this.yearId,
                  month: this.month,
                  budget_name: budget_year_name,
                  is_plan_confirmed: data.is_plan_confirmed,
                  created_at: data.created_at,
                };

                this.monthlyPlanDatas.push(oldPlan);
                this.module_duration[index] = data.module.duration;
              });
            }
            this.loading = false;
          });
      }
    },
    async getTrainers() {
      await axios
        .get(`${this.baseUrl}admin/v1/trainers`)
        .then((response) => {
          this.trainers = response.data.data
            //for inactive trainers
            .filter(function (value) {
              return value.status == 1;
            })
            .map((val) => {
              return { id: val.user.employee_id, name: val.user.name };
            });
        })
        .catch(() => {
          this.toast.error("Not found Trainer!");
        });
    },
    async getEmployeeTypes() {
      await axios
        .get(`${this.baseUrl}admin/v1/employee-types`)
        .then((response) => {
          this.employee_types = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Employee Type");
        });
    },
    async getModuleGroups() {
      await axios
        .get(`${this.baseUrl}admin/v1/module-groups`)
        .then((response) => {
          this.module_groups = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Module Group");
        });
    },
    getModuleGroupsByType(value, index) {
      let id = value.id;
      this.employee_type_id_for_date = id;
      this.filter_module_groups[index] = [];
      this.filter_module_groups[index] = this.module_groups.filter(
        (group_list) => {
          if (group_list.employee_type_id == id) {
            return group_list;
          }
        }
      );
      this.monthlyPlanDatas[index].module_group = "";
      this.monthlyPlanDatas[index].module = "";
      this.monthlyPlanDatas[index].from_date = "";
      this.monthlyPlanDatas[index].to_date = "";
      this.monthlyPlanDatas[index].expired_date = "";
    },
    changeModuleGroup(employee_type_id, index) {
      this.filter_module_groups[index] = this.module_groups.filter(
        (group_list) => {
          if (group_list.employee_type_id == employee_type_id) {
            return group_list;
          }
        }
      );
      this.monthlyPlanDatas[index].module = "";
    },

    getModulesByGroup(value, index) {
      this.filter_modules[index] = [];
      this.filter_modules[index] = value.modules;
    },
    changeModule(moduleGroup, index) {
      this.filter_modules[index] = this.modules.filter((module) => {
        if (moduleGroup.id) {
          //old data select
          for (var i in module.groups) {
            //when a module has many module groups
            if (module.groups[i].id == moduleGroup.id) {
              return module;
            }
          }
        } else {
          //new data select
          for (var j in module.groups) {
            if (module.groups[j].id == moduleGroup) {
              return module;
            }
          }
        }
      });
    },

    getModuleDuration(module_id, index) {
      this.filter_module_duration = this.modules.filter((module) => {
        if (module.id == module_id) {
          return module;
        }
      });
      this.module_duration[index] = this.filter_module_duration[0].duration;
    },

    checkToDate(array) {
      if (array.from_date <= array.to_date) {
        return true;
      } else {
        return false;
      }
    },
    checkExpiredDate(array) {
      var min_expired_date = new Date(array.expired_date);

      if (array.employee_type == 2) {
        min_expired_date.setDate(
          min_expired_date.getDate() +
          this.getPreDefineExpDateForNormal.expiered_date
        );
      }
      if (array.employee_type == 1) {
        min_expired_date.setDate(
          min_expired_date.getDate() -
          this.getPreDefineExpDateForProgram.expiered_date
        );
      }

      var min_expired_date_format =
        moment(min_expired_date).format("YYYY-MM-DD");

      if (array.employee_type == 2) {
        if (array.from_date >= min_expired_date_format) {
          return true;
        } else {
          return false;
        }
      }
      if (array.employee_type == 1) {
        if (array.from_date <= min_expired_date_format) {
          return true;
        } else {
          return false;
        }
      }
    },
    checkEmptyValue(array) {
      let monthlyPlans = Object.values(array);
      if (monthlyPlans.includes(null) || monthlyPlans.includes("")) {
        return false;
      } else {
        return true;
      }
    },

    checktrainerEmpty(array) {
      let trainers = array.trainers;
      if (trainers) {
        if (trainers.length == 0) {
          return false;
        } else {
          return true;
        }
      }
    },

    async createMonthlyPlans() {
      let checkPlan = [];
      let checkToDate = [];
      let checkExpiredDate = [];
      let checkTrainers = [];

      this.monthlyPlanDatas.forEach((array) => {
        let plan = this.checkEmptyValue(array);
        let toDate = this.checkToDate(array);
        let expiredDate = this.checkExpiredDate(array);
        let trainerLists = this.checktrainerEmpty(array);
        checkPlan.push(plan);
        checkToDate.push(toDate);
        checkExpiredDate.push(expiredDate);
        checkTrainers.push(trainerLists);
      });
      if (checkPlan.includes(false)) {
        this.toast.error("Please Fill All Input!");
        return;
      } else if (checkTrainers.includes(false)) {
        this.toast.error("Please Fill All Input!");
        return;
      } else if (checkToDate.includes(false)) {
        this.toast.error("End Date is smaller than From Date!");
        return;
      } else if (checkExpiredDate.includes(false)) {
        if (this.employee_type_id_for_date == 2) {
          this.toast.error(
            "Enrolled Expired Date is minimum 3 days greater than From Date!"
          );
        }
        if (this.employee_type_id_for_date == 1) {
          this.toast.error(
            "Enrolled Expired Date is minimum 7 days less than From Date!"
          );
        }
        return;
      }

      this.isLoading = true;

      axios
        .post(`${this.baseUrl}admin/v1/monthly-plans`, this.monthlyPlanDatas)
        .then(() => {
          this.toast.success("Successfully Monthly Plan Created!");
          // this.$router.push({ name: "monthly-plans" });
          this.getPlansByMonth();
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async toMonthlySchedulePlan() {
      this.$router.push({ name: "monthly-plans" });
    },

    activePlan(item) {
      return (
        item.from_date <= this.current_year_month_day &&
        this.current_year_month_day <= item.to_date
      );
    },
    confirmPlan(item) {
      var change_created_date = new Date(item.created_at);

      change_created_date.setDate(
        change_created_date.getDate() +
        this.getPreDefineCreateDateForDisable.created_date
      );

      var define_created_date =
        moment(change_created_date).format("YYYY-MM-DD");

      // return item.is_plan_confirmed == 1;
      return this.current_year_month_day > define_created_date;
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#monthly-datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    // this.getBugetYearById();
    this.getAllBudgetYear();
    // this.getAllMonths();
    this.getEmployeeTypes();
    this.getAllModules();
    this.getOldMonthlyPlanDatas();
    this.getModuleGroups();
    this.getTrainers();
    this.current_year_month_day = moment().format("YYYY-MM-DD");
  },
  computed: {
    getPreDefineExpDateForNormal() {
      var expiered_date = parseInt(`${this.defineExpDateForNormal}`);
      return {
        expiered_date,
      };
    },
    getPreDefineExpDateForProgram() {
      var expiered_date = parseInt(`${this.defineExpDateForProgram}`);
      return {
        expiered_date,
      };
    },
    getPreDefineCreateDateForDisable() {
      var created_date = parseInt(`${this.defineCreateDateForDisable}`);
      return {
        created_date,
      };
    },
    getBudgetYear() {
      let budget_year_from = moment(this.budget_year_date_from).format("YYYY");
      let budget_year_to = moment(this.budget_year_date_to).format("YYYY");
      let budget_year_start_month = moment(this.budget_year_date_from).format(
        "MM"
      );

      return {
        from: budget_year_from,
        to: budget_year_to,
        startMonth: budget_year_start_month,
      };
    },

    getStartAndlastDay() {
      var year;

      if (this.month >= this.getBudgetYear.startMonth) {
        year = this.getBudgetYear.from;
      } else {
        year = this.getBudgetYear.to;
      }

      var firstDay = moment([year, parseInt(this.month) - 1]).format(
        "YYYY-MM-DD"
      );

      var lastDay = moment(firstDay)
        .clone()
        .endOf("month")
        .format("YYYY-MM-DD");
      return {
        firstDay,
        lastDay,
      };
    },
  },
};
</script>

<style>
.w-75 {
  width: 75px !important;
}

#vue-selectCss ul {
  position: relative !important;
}

.custom-form-control:disabled,
.custom-form-control[readonly] {
  background-color: #efefef;
  opacity: 1;
  cursor: not-allowed;
}

.disable-button {
  cursor: not-allowed;
}

.vs--disabled.plan_active {
  background: unset;
  /* background-color: #25bae747 !important; */
  background-color: #1a84b6 !important;
  -webkit-text-fill-color: #fff !important;
  opacity: 1;
}

.vs--disabled.plan_active .vs__dropdown-toggle,
.vs--disabled.plan_active .vs__search,
.vs--disabled.plan_active .vs__open-indicator,
.vs--disabled.confirmDisable .vs__dropdown-toggle,
.vs--disabled.confirmDisable .vs__search,
.vs--disabled.confirmDisable .vs__open-indicator,
.plan_active.confirmDisable .vs__dropdown-toggle,
.plan_active.confirmDisable .vs__search,
.plan_active.confirmDisable .vs__open-indicator {
  background-color: unset;
}

.form-control[readonly] .plan_active,
.plan_active+.form-control[readonly] {
  /* background-color: #25bae747 !important; */
  background-color: #1a84b6 !important;
  -webkit-text-fill-color: #fff !important;
  opacity: 1;
}

.table>tbody>tr .confirmDisable,
.vs--disabled.confirmDisable {
  /* background: #b5b3b3 !important; */
  /* background-color: #1a84b675 !important; */
  -webkit-text-fill-color: #fff !important;
  background-color: #1a84b652 !important;
  -webkit-text-fill-color: #333 !important;
  cursor: not-allowed;
}

.table>tbody>tr .plan_active,
.vs--disabled.plan_active {
  /* background: #25bae747 !important; */
  background-color: #1a84b6 !important;
  -webkit-text-fill-color: #fff !important;
}

.customHeight {
  height: 100px !important;
}

span.vs__selected {
  background: transparent;
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
  cursor: var(--vs-disabled-cursor);
  background: transparent;
}
</style>

<style scoped>
.modify-button-css {
  width: 68px;
}
</style>

